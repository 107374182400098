.signup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px; 
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
  
}

.headerlogin{
  width: calc(100% + 0.5rem);
  padding-top: 20px; 
  padding-bottom: 40px; 
  -webkit-box-pack: justify;
  -webkit-box-align: center;
  align-items: center;
  margin: 0px;
}
.signup-form {
  margin: 40px;
  padding: 40px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 450px;
}

.form-group {
  text-align: left;
  margin-bottom: 10px;
}

input[type="email"]{
  padding-right: 20px;
}
input[type="text"],
input[type="email"],input[type="password"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  margin-top: 5px;
  font-family: 'Nunito', sans-serif;
}

.checkbox {
  margin-top: 10px;
  display: inline-flex; 
  align-items: center; 
  margin-top: 15px;
  font-size: 16px; 
  padding: 10px; 
}

.checkbox input[type="checkbox"] {
  width: 20px; 
  height: 20px; 
  margin-right: 10px; 
}
.text-agree{
  padding-left: 4px;
}
.checkbox-container {
  font-family: 'Nunito', sans-serif;
  text-align: left;
  margin-left: 2px;
  margin-top: 15px;
  font-size: 12px;
}

.button-enabled{
  margin-top: 22px;
  width: 100%;
  font-family: 'Nunito', sans-serif;
  background-color: var(--primary);
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.button-disabled {
  margin-top: 22px;
  width: 100%;
  background-color: #ccc; 
  color: #666; 
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: not-allowed; 
  font-size: 16px;
  opacity: 0.7; 
}


.signup{
  font-size: 24px;
  line-height: 2rem;
  margin: 1rem 0px;
  color: rgb(79, 79, 79);
}

.close-page {
  font-size: 24px;
  color: var(--kulabox-red);
}

.button-create{
  font-family: 'Nunito', sans-serif;
}
.google-signin {
  flex-direction: column;
  align-items: center;
  text-align: center;
}


.login-link {
  margin-top: 20px;
  text-align: center;
}
.fm{
  display: flex;
}
.login-link a {
  text-decoration: none;
  color: #000dff;
  font-weight: bold;
}

.header-logo-login {
  height:70px;
  max-width: 90px;
  margin-right: 4px;
}









label,
button {
margin-top: 1rem;
}

button {
padding: 0.5rem;
}

.instructions {
  font-size: 0.75rem;
  border-radius: 0.5rem;
  background: #000;
  color: #fff;
  padding: 0.25rem;
  position: relative;
  bottom: -10px;
}

.instructions > svg {
  margin-right: 0.25rem;
}

.offscreen {
  position: absolute;
  left: -9999px;
}

.hide {
  display: none;
}

.valid {
  color: limegreen;
  margin-left: 0.25rem;
}

.invalid {
  color: red;
  margin-left: 0.25rem;
}

.errmsg {
  background-color: lightpink;
  color: firebrick;
  font-weight: bold;
  padding: 0.5rem;
  margin-right: 17px;
  margin-bottom: 0.5rem;
}

.errmsg-login {
  background-color: lightpink;
  color: firebrick;
  font-weight: bold;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}
.line {
  display: inline-block;
}