.menu-item-card {
  width: 300px;
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }
}

.menu-item-card .MuiCardContent-root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu-item-card .MuiTypography-h5 {
  margin-bottom: 10px;
}

.button-container {
  margin-top: 20px;
}

.edit-button,
.delete-button {
  color: #333;
  transition: color 0.3s ease;
}

.delete-button:hover {
  color: #ff5722;
}

.edit-button:hover {
  color: #3fff22;
}
.menu-item-container {
  position: relative;
  overflow: hidden;
  
  .discount-ribbon {
    position: absolute;
    top: 15px;
    right: 40px;
    transform: rotate(45deg);
    background: #ff4757;
    color: white;
    padding: 4px 30px;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    z-index: 2;
    
    &::before {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid #ff4757;
      border-top: 10px solid transparent;
      border-bottom: 10px solid #ff4757;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 0;
      height: 0;
      border-bottom: 15px solid #ff6b81;
      border-left: 15px solid transparent;
    }
  }

  .menu-item-card {
    position: relative;
    z-index: 1;
    transition: transform 0.2s;
    
    &:hover {
      transform: translateY(-5px);
    }
  }
}