.search {
    display: flex;
    align-items: center;
  }
  
  .searchTerm {
    flex: 70%;
    margin-right: 10px; /* Add space between search bar and button */
    padding: 10px 15px;
    border: 1px solid #dfe1e5;
    border-radius: 24px;
    outline: none;
    font-size: 16px;
  }
  
  .add-button {
    padding: 2px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
  }
  
  
  .no-match-found {
    display: flex;
    padding-top: 80px;
    padding-bottom: 80px;
    flex-direction: column; 
    align-items: center;
    justify-content: center; 
    margin-top: 10px; 
    color: #FF0000;
  }
  
  .no-match-found .icon {
    font-size: 50px; 
  }
  
  .no-match-found .message {
    font-size: 15px; 
    margin-top: 5px; 
  }
  

  ///add modal
  .modal-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    max-width: 400px;
    width: 100%;
    max-height: 80vh;
    overflow-y: auto;
  }
  
  .modal-container h2 {
    margin-top: 0;
  }
  
  .modal-container > div {
    margin-bottom: 15px;
  }
  
  .modal-container label {
    display: block;
    margin-bottom: 5px;
  }
  
  .modal-container input[type="text"],
  .modal-container textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
  .modal-container button {
    padding: 10px 20px;
    background-color: var(--primary);
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .modal-container button:hover {
    background-color: orangered;
  }
  