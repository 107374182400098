.tab-list {
  display: flex;
  border-bottom: 2px solid #ddd;
  padding: 0;
  list-style: none;
  margin: 0;
}

.tab-item {
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #444;
  transition: all 0.3s ease;
  z-index: 10;
}

.tab-item:hover {
  background-color: #f0f0f0;
}

.tab-item.selected {
  border-bottom: 5px solid var(--primary);
  background-color: #f0f0f0;
}

.tab-item > svg {
  margin-right: 5px;
}

.tab-panels {
  padding: 20px;
  border: 1px solid #ccc;
}

.tab-panel {
  display: none;
}

.tab-panel.selected {
  display: block;
}
