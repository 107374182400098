.datatable {
    height: 600px;
    padding: 20px;
    
    .datatableTitle{
      width: 100%;
      font-size: 24px;
      color: gray;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
  
      .link{
        text-decoration: none;
        color: green;
        font-size: 16px;
        font-weight: 400;
        border: 1px solid green;
        padding: 5px;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  
    .cellWithImg {
      display: flex;
      align-items: center;
  
      .cellImg {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        object-fit: cover;
        margin-right: 20px;
      }
    }
  
    .cellWithStatus {
      padding: 5px;
      border-radius: 5px;
  
      &.active {
        background-color: rgba(0, 128, 0, 0.05);
        color: green;
      }
      &.pending {
        background-color: rgba(255, 217, 0, 0.05);
        color: goldenrod;
      }
      &.passive {
        background-color: rgba(255, 0, 0, 0.05);
        color: crimson;
      }
    }
  
    .cellAction {
      display: flex;
      align-items: center;
      gap: 15px;
  
      .viewButton {
        padding: 2px 5px;
        border-radius: 5px;
        color: darkblue;
        border: 1px dotted rgba(0, 0, 139, 0.596);
        cursor: pointer;
      }
      
      .deleteButton {
        padding: 2px 5px;
        border-radius: 5px;
        color: crimson;
        border: 1px dotted rgba(220, 20, 60, 0.6);
        cursor: pointer;
      }

      .editButton {
        padding: 2px 5px;
        border-radius: 5px;
        color: rgb(20, 220, 30);
        border: 1px dotted rgb(20, 220, 30);
        cursor: pointer;
      }

      .activateButton {
        padding: 2px 5px;
        border-radius: 5px;
        color: rgb(207, 220, 20);
        border: 1px dotted rgb(207, 220, 20);
        cursor: pointer;
      }
      .blockButton {
        padding: 2px 5px;
        border-radius: 5px;
        color: rgb(247, 5, 17);
        border: 1px dotted rgb(247, 5, 17);
        cursor: pointer;
      }
    }
  }
  ///add modal
.modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  max-width: 400px;
  width: 100%;
  max-height: 80vh;
  overflow-y: auto;
}

.modal-container h2 {
  margin-top: 0;
}

.modal-container > div {
  margin-bottom: 15px;
}

.modal-container label {
  display: block;
  margin-bottom: 5px;
}

.modal-container input[type="text"],
.modal-container textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.modal-container button {
  padding: 10px 20px;
  background-color: var(--primary);
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.modal-container button:hover {
  background-color: orangered;
}