.search {
    display: flex;
    align-items: center;
  }
  
  .searchTerm {
    flex: 70%;
    margin-right: 10px; /* Add space between search bar and button */
    padding: 10px 15px;
    border: 1px solid #dfe1e5;
    border-radius: 24px;
    outline: none;
    font-size: 16px;
  }
  
  .add-button {
    padding: 2px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
  }
  
  
  .no-match-found {
    display: flex;
    padding-top: 80px;
    padding-bottom: 80px;
    flex-direction: column; 
    align-items: center;
    justify-content: center; 
    margin-top: 10px; 
    color: #FF0000;
  }
  
  .no-match-found .icon {
    font-size: 50px; 
  }
  
  .no-match-found .message {
    font-size: 15px; 
    margin-top: 5px; 
  }
  

  .modal-container {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 500px;
    margin: auto;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .form-input,
  .form-select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .form-input:focus,
  .form-select:focus {
    border-color: #007bff; /* Change to your primary color */
    outline: none;
  }
  
  .modal-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .modal-actions button {
    flex: 1;
    margin: 0 5px; /* Add some space between buttons */
  }
  

  // menu-list.scss

.form-select {
  color: black; // Set default color for the dropdown
  background-color: white; // Set a white background for better contrast
  border: 1px solid #ccc; // Optional: add border for better visibility
  padding: 8px; // Optional: add some padding
  font-size: 16px; // Optional: increase font size for readability
}

.form-select option {
  color: black; // Set option text color to black
  background-color: white; 
}
