.modalBackground {
    width: 100vw;
    height: 100vh;
    background-color: rgba(200, 200, 200, 0.5); 
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; 
  }
  
  .modalContainer {
    width: 500px;
    max-width: 90%; /* Ensure modal is responsive */
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 25px;
  }
  .modalContainer .title {
    font-size: 1.2rem !important;
    text-align: center;
  }
  
  
  .titleCloseBtn {
    display: flex;
    justify-content: flex-end;
  }
  
  .titleCloseBtn button {
    background-color: transparent;
    border: none;
    font-size: 23px;
    cursor: pointer;
  }
  
  .modalContainer .body {
    margin-top: 20px; /* Add some space between title and body */
    margin-bottom: 20px; /* Add some space between body and footer */
    font-size: 1.2rem;
    text-align: center;
  }

  .modaltitle {
    font-size:1.2rem;
    text-align: center;
  }
  
  .modalContainer .footer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modalContainer .footer button {
    width: 150px;
    height: 45px;
    margin: 10px;
    border: none;
    background-color: cornflowerblue;
    color: white;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
  }
  
  #cancelBtn {
    background-color: crimson;
  }


  
  