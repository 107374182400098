.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure the overlay is on top of other content */
}

.is-loading {
    width: 200px; /* Adjust width as needed */
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.loading-dots {
    display: flex;
    margin-top: 20px;
}

.loading-dots span {
    width: 10px; /* Adjust size of dots as needed */
    height: 10px;
    margin: 5px;
    border-radius: 50%; /* Makes the dots circular */
    background: #007DB6;
    animation: loading 1s infinite alternate;
}

.loading-dots span:nth-of-type(2) {
    background: #008FB2;
    animation-delay: 0.2s;
}

/* Add styling for other dots as needed */
