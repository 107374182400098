.category-card {
    width: 300px;
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
  
    &:hover {
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    }
  }
  
  .category-card .MuiCardContent-root {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .category-card .MuiTypography-h5 {
    margin-bottom: 10px;
  }
  
  .button-container {
    margin-top: 20px;
  }
  
  .edit-button, .delete-button {
    color: #333;
    transition: color 0.3s ease;
  }
  
  .delete-button:hover {
    color: #ff5722; 
  }
  .edit-button:hover {
    color: #3fff22; 
  }
  